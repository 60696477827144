<template>
  <div class="session">
    <v-form lazy-validation @submit.prevent="requestResetClicked(email)" v-model="validData">
      <v-container>
        <v-row justify="center" class="mt-12">
          <v-img :src="appLogo" max-width="280"></v-img>
        </v-row>
        <v-row justify="center" class="mt-8">
          <h2 class="mb-4 primary--text">Olvidé mi contraseña</h2>
        </v-row>
        <v-row justify="center">
          <p class="mb-4 sub-text">Por favor escribe tu correo electrónico para mandarte los pasos a seguir para recuperarla.</p>
        </v-row>
        <v-row justify="center">
          <v-col md="4">
            <v-text-field
              label="Correo electrónico"
              :rules="rules.email"
              v-model="email">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="4">
            <v-btn color="primary" :disabled="!validData" @click="requestResetClicked(email)" block >Enviar</v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-4">
          <router-link class="secondary--text" to="/session/login">
            <p>Regresa al inicio de sesión dando click aquí</p>
          </router-link>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">Correo enviado</v-card-title>
        <v-card-text>
          Si el correo electrónico está registrado en nuestro sistema, recibirás un correo electrónico con un enlace  pare recuperar tu contraseña en unos minutos
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AppConfig from '@/constants/app-config'
import rules from '@/constants/input-validation'
import { USERS_RESET_EMAIL } from '@/store/actions.type'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      validData: false,
      dialog: false,
      error: null,
      email: '',
      rules,
      appLogo: AppConfig.appLogo
    }
  },
  components: {},
  methods: {
    ...mapActions({
      requestReset: USERS_RESET_EMAIL
    }),
    requestResetClicked(email) {
      this.requestReset(email)
        .then(response => {
          this.dialog = true
        })
        .catch(e => {
          this.dialog = true
        })
    }
  }
}
</script>
<style>
.sub-text {
  width: 296px;
  font-size: 11px;
}
</style>
